<template>
  <div class="sensor-config-page">
    <RouterMenu />
    <div class="sensor-content">
      <Sensor />
    </div>

  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import Sensor from "@/components/sensor/sensor.vue";
export default {
  name: "sensorConfig",
  components: {
      RouterMenu,
      Sensor
  },
  data() {
    return {
      searchKey: "" //搜索关键字
    };
  },
  watch: {
    searchKey: function(newVal) {
      
    }
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.sensor-config-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
}
</style>

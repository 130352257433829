<template>
  <div class="sensor-config-container">
    <div class="sensor-header">
      <div class="line-name">
        <h3>当前线路：线路 xxx</h3>
      </div>
      <el-button class="kr-normal-button close-btn">关闭</el-button>
    </div>
    <!-- 搜索框 -->
    <div class="up-line"></div>
    <div class="search-box">
      <div class="search-item">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="选择线路：">
            <el-select v-model="formData.line" placeholder="活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择接入状态：">
            <el-select v-model="formData.accessStatus" placeholder="活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="连接状态：">
            <el-select v-model="formData.connectStatus" placeholder="活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="search-btn">
              <el-button class="kr-normal-button">查询</el-button>
              <el-link class="open-more" :underline="false" @click="openMoreSearch">
                <template v-if="showMoreSearch">
                  收起
                  <i class="el-icon-arrow-up"></i>
                </template>
                <template v-else>
                  展开
                  <i class="el-icon-arrow-down"></i>
                </template>
              </el-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 2 -->
      <div class="search-item" v-if="showMoreSearch">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="设备MAC：">
            <el-input v-model="formData.macWord" placeholder="设备MAC关键字"></el-input>
          </el-form-item>
          <el-form-item label="充电塔名： ">
            <el-input v-model="formData.townName" placeholder="充电塔名关键字"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="down-line"></div>
    <!-- 内容列表 -->
    <div class="sensor-content">
      <div class="sensor-header">
        <div class="line-name">
          <h3>已接入设备列表：共9台</h3>
        </div>

        <div class="sensor-content-btn">
          <el-button class="kr-normal-button" @click="addEquipment">新增设备</el-button>
          <el-button class="kr-normal-button">展示全部</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="sensor-list-box">
        <!-- left -->
        <div class="left-tower-list">
          <ul>
            <li v-for="(item, index) in deviceList" :key="index" :class="{'no-connected':!item.isConnected}">
              <div class="tower-item">{{item.energyTowerName}}（{{item.isConnected?'已连接':'未连接'}}）</div>
            </li>
            <li>
              <div class="tower-item">更多充电塔....</div>
            </li>
          </ul>
        </div>
        <!-- right -->
        <div class="right-list-box">
          <ul>
            <li v-for="(item, index) in 9" :key="index">
              <div class="tower-item-content">
                <h3 class="title">充电塔1</h3>
                <div class="tower-info">
                  <div class="tower-item-info">
                    <div class="info-title">安装位置：</div>
                    <div class="info-content">线路x 杆塔x</div>
                  </div>
                  <div class="tower-item-info">
                    <div class="info-title">设备MAC：</div>
                    <div class="info-content">12sd1212312</div>
                  </div>
                  <div class="tower-item-info">
                    <div class="info-title">设备IP：</div>
                    <div class="info-content">122.12.44.12</div>
                  </div>
                  <div class="tower-item-info">
                    <div class="info-title">上次在线：</div>
                    <div class="info-content">2021-02-01 10:00:00</div>
                  </div>
                  <div class="tower-item-info">
                    <div class="info-title">安装日期：</div>
                    <div class="info-content">2021-01-01 10:00:00</div>
                  </div>
                  <div class="tower-item-info">
                    <div class="info-title">连接状态：</div>
                    <div class="info-content">已连接</div>
                  </div>
                </div>
                <!-- 按钮 -->
                <div class="tower-item-btns">
                  <el-button class="kr-normal-button" @click="handleEdit">修改</el-button>
                  <el-button class="kr-normal-button" @click="deleteSensor">删除</el-button>
                  <el-button class="kr-normal-button">调试</el-button>
                </div>
              </div>
            </li>
          </ul>
          <div class="pager-class">
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增/修改弹窗 -->
    <el-dialog class="my-dialog" :title="sensorDialogTitle" :close-on-click-modal="false" :visible.sync="sensorDialogVisible" @closed="closedDialog('sensorForm')">
      <el-form :model="sensorForm" :rules="sensorFormRules" ref="sensorForm">
        <el-form-item label="充电塔名称：" :label-width="formLabelWidth" prop="towerName">
          <el-input v-model.trim="sensorForm.towerName" auto-complete="off" placeholder="请输入充电塔名称"></el-input>
        </el-form-item>
        <el-form-item label="注册码：" :label-width="formLabelWidth" prop="registrationCode">
          <el-input v-model.trim="sensorForm.registrationCode" auto-complete="off" placeholder="请输入注册码"></el-input>
        </el-form-item>
        <el-form-item label="设备MAC：" :label-width="formLabelWidth" prop="equipmentMac">
          <el-input v-model.trim="sensorForm.equipmentMac" auto-complete="off" placeholder="请输入设备MAC"></el-input>
        </el-form-item>
        <el-form-item label="所属线路：" :label-width="formLabelWidth" prop="belongLine">
          <el-select v-model.trim="sensorForm.belongLine" placeholder="请选择所属线路">
            <el-option label="123" value="杆塔1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属杆塔：" :label-width="formLabelWidth" prop="belongTower">
          <el-select v-model.trim="sensorForm.belongTower" placeholder="请选择所属杆塔">
            <!-- <el-option v-for="(item, index) in robotTypeOption" :key="index" :label="item.label" :value="item.type"></el-option> -->
            <el-option label="123" value="杆塔1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="sensorDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增/修改弹窗 -->
    <el-dialog class="my-dialog debug-dialog-box" title="调试" :close-on-click-modal="false" :visible.sync="debugDialogVisible" @closed="closedDialog('sensorForm')">
      <Debug />
    </el-dialog>


  </div>
</template>
<script>
import Debug from "@/components/sensor/debug.vue";
import { 
  getLineDeviceSensorList,
  getLineDeviceSensorDetail,
  sensorAddData 
  } from "@/http/sensorApi.js";
export default {
  name: "sensor",
  beforeCreate: function () {
    document.getElementsByTagName("body")[0].setAttribute("style", "height:auto;");
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].removeAttribute("style");
  },
  components: {
    Debug
  },
  data() {
    return {
      formData: {
        line: "",
        accessStatus: "",
        connectStatus: "",
        macWord: "",
        townName: "",
      },
      showMoreSearch: false, //是否展开更多搜索
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      sensorDialogTitle: "新增设备",
      sensorDialogVisible: false,
      sensorForm: {
        towerName: "",
        registrationCode: "",
        equipmentMac: "",
        belongLine: "",
        belongTower: "",
      },
      debugDialogVisible:false,
      formLabelWidth: "5vw",
      isAdd: true,
      lineId:9,
      deviceList:[],//设备列表
      sensorFormRules:{
        towerName: [{ required: true, message: "请输入充电塔名称", trigger: "blur" }],
        registrationCode: [{ required: true, message: "请输入注册码", trigger: "blur" }],
        equipmentMac: [
          { required: true, message: "请输入设备MAC", trigger: "blur" },
          {
            required: true,
            min: 11,
            max: 12,
            message: "Mac地址为12位",
            trigger: "blur"
          }
        ],
        belongLine: [{ required: true, message: "请选择所属线路", trigger: "blur" }],
        belongTower: [{ required: true, message: "请选择所属杆塔", trigger: "blur" }],
      }
    };
  },
  watch: {},
  mounted() {
    this.getLineDeviceSensorList();
    this.getLineDeviceSensorDetail();
  },
  methods: {
    // 获取线路设备列表
    async getLineDeviceSensorList() {
      let _res = await getLineDeviceSensorList({ 
        lineId: this.lineId,
      });
      
      let res_json = JSON.parse(_res);
      console.log(res_json)
      if(res_json.rows.length > 0) {
        this.deviceList = res_json.rows;
      }
      console.log(this.deviceList)
    },
    // 查询线路设备详细信息
    async getLineDeviceSensorDetail() {
      let _res = await getLineDeviceSensorDetail({ 
        lineId:this.lineId, 
        // isConnected:true,
        // fuzzyMac: "x",
        // fuzzyEnergyTowerName:"",
        pageNumber:1,
        pageSize:10
      });
      
      let res_json = JSON.parse(_res);
      console.log(res_json)
      
    },
    openMoreSearch() {
      this.showMoreSearch = !this.showMoreSearch;
    },
    // 对话框保存按钮
    save() {
      if (this.isAdd) {
        this.addSubmit();
      } else {
        this.updateSubmit();
      }
    },
    // 添加操作
    addSubmit() {
      // let params = this.sensorForm;
      let params = {
        "energyTowerName": "4#充电塔",
        "deviceMac": "qqqqqqqqqqqq",
        "lineId": 9,
        "towerId": 10,
        "installDate": "2021-10-12 00:00:00"
      };
      console.log(params)
      this.$refs.sensorForm.validate(valid => {
        if (valid) {
          sensorAddData(params)
            .then(res => {
              if (res.meta.code == 200) {
                this.$message.success(res.meta.msg);
                this.sensorDialogVisible = false;
                //this.getRobotTableData();
              } else {
                this.$message.warning(res.meta.msg);
              }
            })
            .catch(err => {
              if (err.status == 500) {
                this.$message.warning(err.data.data);
              }
            });
        }
      });
    },
    // 点击分页页码
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // 调用分页查询接口
    },
    // 新增
    addEquipment() {
      this.sensorDialogVisible = true;
    },
    // 编辑按钮
    handleEdit(row) {
      //this.robotForm = JSON.parse(JSON.stringify(row));
      this.isAdd = false;
      this.sensorDialogTitle = "修改设备";
      this.sensorDialogVisible = true;
    },
    // 关闭对话框时回调
    closedDialog(formName) {
      this.$refs[formName].clearValidate();
      this.isAdd = true;
      this.sensorDialogTitle = "新增设备";
      //   this.sensorForm = {
      //     fgtpIp: "192.168.1.164",
      //     lineId: "",
      //     name: "",
      //     port: 3000,
      //     robotIp: "192.168.1.22",
      //     robotMac: "",
      //     robotType: "",
      //     serialNumber: ""
      //   };
    },
    // 删除
    deleteSensor(row) {
      this.$confirm("是否确认删除设备：  xxxxx?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err, "取消操作");
        });
    },
  },
};
</script>

<style lang="scss">
  .debug-dialog-box .el-dialog {
    width: 70vw !important;
  }
</style>

<style lang="scss" scoped>
.sensor-list-box {
  display: flex;
  justify-content: space-between;
  .left-tower-list {
    width: 15vw;
    max-height: 42vh;
    overflow-y: auto;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        .tower-item {
          color: #ffffff;
          font-size: 0.85vw;
          cursor: pointer;
          padding: 0.8vh 0;
          border: 1px solid #00a880;
          margin-bottom: 0.5vh;
          text-align: center;
        }
        &.no-connected {
          .tower-item {
                color: #f56c6c;
          }
        }
      }
    }
  }
  .right-list-box {
    border: 1px solid #00a880;
    width: 66vw;
    padding: 1.2vw;
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border: 1px solid #00a880;
        color: #ffffff;
        padding: 1.5vh 1.6vw;
        margin-bottom: 2vh;
        .tower-item-content {
          text-align: center;
          .title {
            font-size: 0.95vw;
          }
          .tower-info {
            padding: 0.6vh 0;
            .tower-item-info {
              display: flex;
              align-items: center;
              margin-bottom: 1vh;
              font-size: 0.85vw;
              .info-title {
                width: 5vw;
                text-align: left;
              }
              .info-content {
                text-align: left;
              }
            }
          }
          .tower-item-btns {
            margin-top: 0.8vh;
          }
        }
      }
    }
  }
}
.sensor-config-container {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 2vh;
  .sensor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .line-name {
      color: #ffffff;
      font-size: 0.9vw;
    }
  }
  .open-more {
    color: #ffffff;
    font-size: 1.2vh;
    margin-left: 0.5vw;
  }
  .search-btn {
    margin-left: 1vw;
  }
  .search-box {
    padding-top: 3vh;
    padding-bottom: 1.5vh;
  }
  .pager-class {
    display: flex;
    justify-content: center;
  }
}
</style>

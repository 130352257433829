<template>
  <div class="debug-container">
    <div class="header-box">
      <div class="header-right">
        <ul>
          <li>
            <p class="title">设备：</p>
            <p class="content">充电塔1</p>
          </li>
          <li>
            <p class="title">安装位置：</p>
            <p class="content">线路x 杆塔x</p>
          </li>
          <li class="status">
            <p class="title">连接状态：</p>
            <p class="content connected">已连接</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 模式配置 -->
    <div class="mode-debug-content">
      <div class="network-config-left">
        <div class="mode-select">
          <el-radio-group v-model="networkData.mode">
            <el-radio label="网络透传模式"></el-radio>
          </el-radio-group>
        </div>
        <!-- 服务器配置 -->
        <div class="serve-config-box config-item-box">
          <h3 class="title">服务器配置</h3>
          <div class="serve-config-content flex">
            <div class="config-item m-r">
              <p class="tit">服务器地址：</p>
              <el-select v-model.trim="networkData.serverName">
                <el-option label="121.46.26.217" value="1"></el-option>
              </el-select>
            </div>
            <div class="config-item">
              <p class="tit">服务器端口：</p>
              <el-select v-model.trim="networkData.serverName">
                <el-option label="7444" value="1"></el-option>
              </el-select>
            </div>
          </div>
          <div class="serve-config-content flex">
            <div class="config-item">
              <p class="tit">&emsp;连接类型：</p>
              <el-select v-model.trim="networkData.serverName" class="m-r">
                <el-option label="TCP" value="1"></el-option>
              </el-select>
              <el-select v-model.trim="networkData.serverName">
                <el-option label="长连接" value="1"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- 服务器配置 -->
        <div class="serve-config-box config-item-box">
          <h3 class="title">心跳包配置</h3>
          <div class="serve-config-content">
            <div class="config-item m-r">
              <p class="tit width-6">心跳时间(秒)：</p>
              <el-input v-model.trim="networkData.serverName" auto-complete="off" placeholder="请输入心跳时间" class="width-6"></el-input>
            </div>
          </div>
          <div class="serve-config-content">
            <div class="config-item m-r">
              <p class="tit width-6">心跳数据：</p>
              <el-input v-model.trim="networkData.serverName" auto-complete="off" placeholder="请输入心跳数据" class="width-6 m-r"></el-input>
              <el-checkbox v-model="networkData.hex1">Hex</el-checkbox>
            </div>
          </div>

          <div class="serve-config-content flex">
            <div class="config-item">
              <p class="tit width-6">心跳发送方式：</p>
              <el-select v-model.trim="networkData.serverName">
                <el-option label="向服务器发送心跳包" value="1"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- 注册包配置 -->
        <div class="serve-config-box config-item-box">
          <h3 class="title">注册包配置</h3>

          <div class="serve-config-content flex">
            <div class="config-item">
              <p class="tit width-6">注册包发送方式：</p>
              <el-select v-model.trim="networkData.serverName">
                <el-option label="与服务器建立连接时发送一次" value="1"></el-option>
              </el-select>
            </div>
          </div>

          <div class="serve-config-content">
            <div class="config-item m-r">
              <p class="tit width-6">注册数据类型：</p>
              <el-select v-model.trim="networkData.serverName" class="width-6 m-r">
                <el-option label="自定义" value="1"></el-option>
              </el-select>
              <el-input v-model.trim="networkData.serverName" auto-complete="off" placeholder="请输入心跳数据" class="width-6 m-r"></el-input>
              <el-checkbox v-model="networkData.hex1">Hex</el-checkbox>
            </div>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="config-btn flex-center">
          <el-button class="kr-normal-button m-r">取消</el-button>
          <el-button class="kr-normal-button">保存并更新所有配置</el-button>
        </div>
      </div>
      <div class="data-config-right">
        <!-- 接收数据 -->
        <div class="data-item-box">
          <h3 class="title">接收数据</h3>
          <div class="data-item-content">
            <p>123</p>
            <p>dvvdff</p>
          </div>
          <div class="btn">
            <el-button class="kr-normal-button">清空所有</el-button>
          </div>
        </div>
        <!-- 发送数据 -->
        <div class="data-item-box">
          <h3 class="title">发送数据</h3>
          <div class="data-item-content">
            <p>123</p>
            <p>dvvdff</p>
          </div>
          <div class="btn">
            <el-button class="kr-normal-button">清空所有</el-button>
          </div>
        </div>
        <!-- 系统命令 -->
        <div class="data-item-box">
          <div class="sys-command-list data-item-content">
            <el-checkbox-group v-model="networkData.checkList">
              <el-checkbox :label="'系统命令'+index" v-for="(item,index) in 8" :key="index"></el-checkbox>
             
            </el-checkbox-group>
          </div>
          <div class="btn">
            <el-button class="kr-normal-button">清空所有</el-button>
          </div>
        </div>
                <!-- 自定义命令 -->
        <div class="data-item-box">
          <div class="data-item-textarea">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="自定义命令"
                v-model="networkData.diycommand">
              </el-input>
          </div>
          <div class="btn">
            <el-button class="kr-normal-button">发送</el-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      networkData: {
        mode: "",
        serverName: "1",
        hex1: false,
        checkList: ["复选框 A"],
        diycommand:''
      },
    };
  },
  //   props: ["weatherList"],

  methods: {},
};
</script>

<style lang="scss">
.mode-debug-content {
  .el-input__icon {
    line-height: 0;
  }
}
</style>
<style scoped lang="scss">
.debug-container {
  padding: 0 2vw;
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .m-r {
    margin-right: 1vw;
  }
  .width-6 {
    width: 6vw;
  }
  .mode-debug-content {
    display: flex;
    .mode-select {
      .el-radio {
        color: #ffffff;
      }
    }
    .network-config-left {
      border-right: 1px solid #36e6bd;
      padding-right: 2vw;
    }
    .data-config-right {
      padding-left: 2vw;
      h3.title {
        color: #ffffff;
        font-size: 0.8vw;
      }
      .data-item-box {
          .sys-command-list {
              margin-top: 2vh;
              .el-checkbox {
                  margin-bottom: 1vh;
              }
          }
        .data-item-content {
          border: 1px solid #36e6bd;
          width: 28vw;
          padding: 0.5vw;

          p {
            color: #ffffff;
            font-size: 0.6vw;
          }
        }
        .data-item-textarea {
            width: 28vw;
            margin-top: 2vh;
            .el-textarea__inner {
                background-color: #f5f5f5;
            }
        }
        .btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 0.5vw;
        }
      }
    }
  }
  .config-item-box {
    border: 1px solid #36e6bd;
    padding: 1vw;
    margin: 1.2vh 0;
  }
  .serve-config-box {
    h3.title {
      color: #ffffff;
      font-size: 0.8vw;
    }

    .serve-config-content {
      .config-item {
        display: flex;
        align-items: center;
        .tit {
          color: #ffffff;
          font-size: 0.7vw;
        }
      }
    }
  }
}
.header-box {
  .header-right {
    display: flex;
    justify-content: flex-end;
    ul {
      display: flex;
      align-items: center;
      color: #ffffff;
      list-style: none;
      li {
        display: flex;
        align-items: center;
        margin-right: 1vw;
        font-size: 0.85vw;
        .content {
          color: #3a8ee6;
        }
        &.status {
          .connected {
            color: #36e6bd;
          }
          .noconnected {
            color: #f56c6c;
          }
        }
      }
    }
  }
}
</style>

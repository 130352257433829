/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-05-18 15:07:39
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\sensorApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 气象传感器接口界面接口
import axios from "./ajax.js"; // 导入ajax

// 查询线路设备列表
// TODO://
export const getLineDeviceSensorList = params => {
    return axios({
        url: `/eprdms/meteorology/sensor/get/device/list`,
        method: "get",
        params
    });
};

// TODO://
// 查询线路设备详细信息
export const getLineDeviceSensorDetail = data => {
    return axios({
        url: `/eprdms/meteorology/sensor/get/device/detail/list`,
        method: "POST",
        data
    });
};

// TODO://
// 新增设备
export const sensorAddData = data => {
    return axios({
        url: `/eprdms/meteorology/sensor/add`,
        method: "POST",
        data
    });
};